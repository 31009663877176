import React from 'react'
import SectionProductsHeroBanner from './Section_products_hero_banner'
import SectionProductsItemBannerGherkin from './Section_products_item_banner_gherkin'
import SectionProductsItemBannerJalapeno from './Section_products_item_banner_jalapeno'

const Products = () =>
  <div id="products-page">
    <SectionProductsHeroBanner />
    <SectionProductsItemBannerGherkin />
    <SectionProductsItemBannerJalapeno />
  </div>

export default Products
