import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionProductsHeroBanner = () => {
  return (
    <section className="products_hero_banner">
      <div className="products_hero_banner_bg_top" />
      <div className="products_hero_banner_bg_bottom">
        <div className="products_hero_banner_tile">
          <div className="products_hero_banner_tile_left">&nbsp;</div>
          <div className="products_hero_banner_tile_content">
            <h3>
              <FormattedMessage
                id="products.hero.title"
                defaultMessage="Our Products:"
              />
            </h3>
            <hr />
            <p>
              <FormattedMessage
                id="products.hero.description"
                defaultMessage="
                We promise all of our products are consistently produced and controlled according to quality standards. We also comprehensively guarantee the safety of raw materials. We are proud to introduce our outstanding natural lactobacillus fermentation ZERO nitrites pickled gherkins and jalapeno to you.
                "
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionProductsHeroBanner
