import React from 'react'
import ReactDOM from 'react-dom'
import './style.scss'
import App from './App'
import 'babel-polyfill'
import { hydrate, render } from 'react-dom'
import { loadComponents, getState } from 'loadable-components'

window.snapSaveState = () => getState()
const appElement = document.getElementById('app')

if (!window.Intl) {
  require.ensure(
    ['intl', 'intl/locale-data/jsonp/en.js', 'intl/locale-data/jsonp/zh.js'],
    require => {
      require('intl')
      require('intl/locale-data/jsonp/en.js')
      require('intl/locale-data/jsonp/zh.js')

      if (appElement.hasChildNodes()) {
        loadComponents().then(() => {
          hydrate(<App />, appElement)
        })
      } else {
        render(<App />, appElement)
      }
    },
  )
} else {
  ReactDOM.render(<App />, document.getElementById('app'))
}
