import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionBlockquote = () => {
  return (
    <section className="section-blockquote">
      <div className="section-blockquote_content-wrapper">
        <div className="section-blockquote_content">
          <div className="quote">
            <span className="quote-content">&ldquo;</span>
          </div>
          <blockquote>
            <FormattedMessage
              id="home.section.blockquote.content"
              defaultMessage="AnHui HuaYi Foods Co., Ltd has been a trusted supplier to McDonald's, Burger King, Subway, McCormick, KFC, Griffith Foods, Kerry Group, The Kraft Heinz Company, and others for over 20 years."
            />
          </blockquote>
          <div className="quote">
            <span className="quote-content">&rdquo;</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionBlockquote
