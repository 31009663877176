import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const haccp_cert_src = require('imgs/cert-HACCP.png')
const gmp_cert_src = require('imgs/cert-GMP.png')
const iso22000_cert_src = require('imgs/cert-ISO22000.png')
const fssc22000_cert_src = require('imgs/cert-fssc22000.png')
const taccp_cert_src = require('imgs/cert-TACCP.png')

const SectionHistory = () => {
  return (
    <section className="section-history">
      <div className="section-history_content-wrapper">
        <div className="section-history_content-img">
          <p>
            <strong>
              <FormattedMessage
                id="home.section.history.president.title"
                defaultMessage="Anhui HuaYi Foods Company President, "
              />{' '}
            </strong>
            <FormattedMessage
              id="home.section.history.president.name"
              defaultMessage="Yongjun Zhang"
            />
          </p>
        </div>
        <div className="flex-column">
          <div className="section-history_content-about">
            <h2>
              <FormattedMessage
                id="home.section.history.content_about.title"
                defaultMessage="About AnHui HuaYi"
              />
            </h2>
            <p>
              <FormattedMessage
                id="home.section.history.content_about.content"
                defaultMessage="AnHui HuaYi Foods Co., Ltd was founded in 1994. From its inception it has comprehensively managed the planting, manufacturing, and marketing of all of its pickled products. In 2003, AnHui HuaYi became a Sino-Japanese joint venture, but eventually ownership reverted back to Chinese control. AnHui HuaYi is the leading manufacturer of gherkins in all of China, and its pickled cucumbers, jalapeno chilli peppers, and vegetables are exported internationally to USA, Japan, and Korea. Our approach to business has always been, &apos;Quality and Customer First&apos;."
              />
            </p>
            <Link to="/history" className="button-small button-small-green_bg">
              <FormattedMessage
                id="home.section.history.content_about.link"
                defaultMessage="Read more &ndash;"
              />
            </Link>
          </div>
          <div className="section-history_content-cert">
            <div className="section-history_content-cert_text">
              <h2>
                <FormattedMessage
                  id="home.section.history.content_cert.title"
                  defaultMessage="Guaranteed Quality"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="home.section.history.content_cert.content"
                  defaultMessage="AnHui HuaYi is extremely committed to manufacturing our food products in line with strict international food safety standards. We are dedicated to achieving the highest quality possible, and are proud of our results. AnHui HuaYi habitually invests in research and development in an effort to continually raise the bar in our industry."
                />
              </p>
              <Link
                to="/technology"
                className="button-small button-small-green_bg no-mobile"
              >
                <FormattedMessage
                  id="home.section.history.content_cert.link"
                  defaultMessage="Read more &ndash;"
                />
              </Link>
            </div>
            <div className="section-history_content-cert_imgs">
              <ul className="cert_imgs-list">
                <li>
                  <img src={haccp_cert_src} alt="华怡食品 - HACP cert" />
                </li>
                <li>
                  <img src={gmp_cert_src} alt="华怡食品 - GMP cert" />
                </li>
                <li>
                  <img src={iso22000_cert_src} alt="华怡食品 - ISO22000" />
                </li>
                <li>
                  <img src={fssc22000_cert_src} alt="华怡食品 - FSSC22000" />
                </li>
                <li>
                  <img src={taccp_cert_src} alt="华怡食品 - taccp cert" />
                </li>
              </ul>
              <Link
                to="/technology"
                className="button-small button-small-green_bg no-desktop"
              >
                <FormattedMessage
                  id="home.section.history.content_cert.link"
                  defaultMessage="Read more &ndash;"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionHistory
