import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const itemImage1Src = require('imgs/product_jalapeno_1.jpg')
const itemImage2Src = require('imgs/product_jalapeno_2.jpg')
const itemImage3Src = require('imgs/product_jalapeno_3.jpg')
const itemImage4Src = require('imgs/product_jalapeno_4.jpg')
const itemImage1 = {
  background: 'url(' + itemImage1Src + ')no-repeat',
  backgroundSize: 'cover',
}
const itemImage2 = {
  background: 'url(' + itemImage2Src + ')no-repeat',
  backgroundSize: 'cover',
}
const itemImage3 = {
  background: 'url(' + itemImage3Src + ')no-repeat',
  backgroundSize: 'cover',
}
const itemImage4 = {
  background: 'url(' + itemImage4Src + ')no-repeat',
  backgroundSize: 'cover',
}

const SectionProductsItemBannerJalapeno = () => {
  return (
    <section className="products_item_banner products_jalapeno">
      <div className="products_item_banner_main_description">
        <div className="products_item_banner_main_description_wrapper">
          <h3>
            <FormattedMessage
              id="products.item.jalapeno.title"
              defaultMessage="Our Pickled Jalapeno Peppers:"
            />
          </h3>
          <p>
            <FormattedMessage
              id="products.item.jalapeno.description"
              defaultMessage="
            The jalapeno is a medium-sized chili pepper, which is a pod type cultivar of the species Capsicum family that originated from South America. Our Pickled Jalapeno Peppers is fermented by Lactobacillus bacteria and it gives more nutrients than fresh jalapeno.  We produce excellent delicious pickled jalapeno rings and you can enjoy your jalapeno rings with nachos, sandwiches, salads and more!
            "
            />
          </p>
          <p>
            <FormattedMessage
              id="products.item.jalapeno.cta"
              defaultMessage="
            For all of the enquiries about our pickled jalapeno peppers or any custom enquiries:
            "
            />
          </p>
          <p>
            <Link to="/contact" className="button-small button-small-green_bg">
              <FormattedMessage
                id="products.item.jalapeno.contact.link"
                defaultMessage="Contact HuaYi &ndash;"
              />
            </Link>
          </p>
        </div>
      </div>
      <div className="products_item_banner_images">
        <div className="products_item_banner_image_row">
          <div className="products_item_banner_image" style={itemImage1}>
            &nbsp;
          </div>
          <div className="products_item_banner_image" style={itemImage2}>
            &nbsp;
          </div>
        </div>
        <div className="products_item_banner_image_row">
          <div className="products_item_banner_image" style={itemImage3}>
            &nbsp;
          </div>
          <div className="products_item_banner_image" style={itemImage4}>
            &nbsp;
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionProductsItemBannerJalapeno
