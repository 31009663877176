import React from 'react'
import PropTypes from 'prop-types'
import SectionTechDescription1Content from './Section_tech_description_1_content'

const { bool } = PropTypes
const farm_src = require('imgs/farm.svg')

const SectionTechDescription1 = ({ isMobile }) => {
  if (isMobile) {
    return (
      <section className="tech_description_1">
        <SectionTechDescription1Content />
      </section>
    )
  } else {
    return (
      <section className="tech_description_1">
        <div className="tech_description_1_wrapper">
          <div className="tech_description_1_image_container">
            <img
              className="tech_description_1_image"
              src={farm_src}
              alt="华怡食品 - YiYang"
            />
          </div>
          <SectionTechDescription1Content />
        </div>
      </section>
    )
  }
}

SectionTechDescription1.propTypes = {
  isMobile: bool,
}

export default SectionTechDescription1
