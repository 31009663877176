import React from 'react'
import { FormattedMessage } from 'react-intl'

const yiyang_logo_src = require('imgs/yiyang-logo.svg')

const SectionValue = () => {
  return (
    <section className="section-yiyang">
      <div className="section-yiyang_content-wrapper">
        <div className="section-yiyang_content">
          <div className="yiyang-wrapper">
            <img src={yiyang_logo_src} alt="华怡食品 - YiYang" />
            <p className="pullQuote">
              <FormattedMessage
                id="home.section.yiyang.content"
                defaultMessage="YiYang Food is a subsidiary of AnHui HuaYi Foods Co., Ltd, handling the final fermentation process of our pickled gherkins, as well as packaging of products for shipment."
              />
            </p>
          </div>
          {/* <a href="#" className="button button-primary-green"> */}
          {/*   <FormattedMessage */}
          {/*     id="home.section.yiyang.link" */}
          {/*     defaultMessage="Read more &ndash;" */}
          {/*   /> */}
          {/* </a> */}
        </div>
      </div>
    </section>
  )
}

export default SectionValue
