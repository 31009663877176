import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionTechDescription2 = () => {
  return (
    <section className="tech_description_2">
      <ul className="tech_desc_list">
        <li className="tech_desc_list_1">
          <h4>
            <FormattedMessage
              id="tech.description_2.list_1.content_header"
              defaultMessage="
              Our GAP practice applied on farm including
              "
            />
          </h4>
        </li>
        <li>
          <h5>
            <FormattedMessage
              id="tech.description_2.list_1.item_1.content_header"
              defaultMessage="
              Fully Assessment of growing environment:
              "
            />
          </h5>
          <p>
            <FormattedMessage
              id="tech.description_2.list_1.item_1.content_body"
              defaultMessage="
              Typically, these assessment are mainly samples and records
              parameters such as temperature, humidity, moisture, soils, and other
              important health-affecting factors. In this way, we can maintain our
              agricultural production in a given area. The cultivation of a piece
              of land can be achieved by minimising the environmental impacts per
              unit of area and time, which means reducing the land use intensity
              in most cases.
              "
            />
          </p>
        </li>
        <li>
          <h5>
            <FormattedMessage
              id="tech.description_2.list_1.item_2.content_header"
              defaultMessage="
              Comprehensive Soil test:
              "
            />
          </h5>
          <p>
            <FormattedMessage
              id="tech.description_2.list_1.item_2.content_body"
              defaultMessage="
              Soil testing provides valuable information on important soil
              characteristics and can greatly assist in cost effective decisions
              about fertilisers and other soil additions on farm. our soil test
              including hundreds of different elements testing, for example: Soil
              type report, Organic carbon, Soil PH.
              "
            />
          </p>
        </li>
        <li>
          <h5>
            <FormattedMessage
              id="tech.description_2.list_1.item_3.content_header"
              defaultMessage="
              Measurement of Irrigation water Value:
              "
            />
          </h5>
          <p>
            <FormattedMessage
              id="tech.description_2.list_1.item_3.content_body"
              defaultMessage="
              Irrigation water is the water used as the artificial application to
              soil. It needs to be applied with correct amounts and frequency, for
              optimal soil infiltration and plant growth. Irrigation water with
              good value can increase agricultural production and productivity.
              The irrigation water value readily reflects current soil conditions,
              but is subject to seasonal variations.
              "
            />
          </p>
        </li>
      </ul>

      <ul className="tech_desc_list">
        <li className="tech_desc_list_2">
          <h4>
            <FormattedMessage
              id="tech.description_2.list_2.content_header"
              defaultMessage="
              Comprehensively Guarantee the safety of raw materials
              "
            />
          </h4>
        </li>
        <li>
          <h5>
            <FormattedMessage
              id="tech.description_2.list_2.item_1.content_header"
              defaultMessage="
              Source and Safe aspect:
              "
            />
          </h5>
          <p>
            <FormattedMessage
              id="tech.description_2.list_2.item_1.content_body"
              defaultMessage="
              Pick fresh cucumber from our fully GMO management base farm, while
              152 items of Pesticide Residue Test are also applied on our fresh
              picked cucumber.
              "
            />
          </p>
        </li>
        <li>
          <h5>
            <FormattedMessage
              id="tech.description_2.list_2.item_2.content_header"
              defaultMessage="
              Clean aspect:
              "
            />
          </h5>
          <p>
            <FormattedMessage
              id="tech.description_2.list_2.item_2.content_body"
              defaultMessage="
              Apply at least twice AUTO cleaning process on our fresh picked
              cucumber. We create our unique cleaning processing line with our own
              washing and brushing system. Our auto brushing processing line can
              washing, clean and brushing each individual cucumber.
              "
            />
          </p>
        </li>
        <li>
          <h5>
            <FormattedMessage
              id="tech.description_2.list_2.item_3.content_header"
              defaultMessage="
              Fresh Aspect:
              "
            />
          </h5>
          <p>
            <FormattedMessage
              id="tech.description_2.list_2.item_3.content_body"
              defaultMessage="
              since Picked from Farm until start the fermentation process, we
              promise all individual fresh cucumber can complete this process in
              maximum 24 hours.
              "
            />
          </p>
        </li>
      </ul>
    </section>
  )
}

export default SectionTechDescription2
