import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionHistoryHeroBanner = () => {
  return (
    <section className="history_hero_banner">
      <div className="history_hero_banner_bg_top" />
      <div className="history_hero_banner_bg_bottom">
        <div className="history_hero_banner_tile">
          <div className="history_hero_banner_tile_left">&nbsp;</div>
          <div className="history_hero_banner_tile_content">
            <h3>
              <FormattedMessage
                id="history.hero.title"
                defaultMessage="Our History:"
              />
            </h3>
            <hr />
            <p>
              <FormattedMessage
                id="history.hero.description.line1"
                defaultMessage="
                In the early 1990s, Chinese food manufacturing especially the industry of vegetable and fruits is still under very basic level. Overall the awareness of food safety is quite lack among food manufacturers. At the same period, several well-known International Catering Group started to get into the Chinese market.
                "
              />
            </p>
            <p>
              <FormattedMessage
                id="history.hero.description.line2"
                defaultMessage="
                Pickle, as one of the indispensable part of western style meals, has an increasing demand in China since 1990 and requires very high safety awareness. It is clear that the pickle manufacturing in China cannot meet the needs of these new catering. Thus, the founder of Yiyang food Co., Mr Zhang started this pickle business.
                "
              />
            </p>
            <p>
              <FormattedMessage
                id="history.hero.description.line3"
                defaultMessage="
                Mr Zhang believes in 'Peace of Mind' food produce, which focuses on the whole practices of creating better and healthier products. This started from the check and select each seeds to comprehensive pesticide residue control and the lactobacillus fermentation process, until the final ready to eat products.
                "
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionHistoryHeroBanner
