import React from 'react'
import SectionBlockquote from '../../components/Section_blockquote'
import { FormattedMessage } from 'react-intl'

const Contact = () =>
  <div id="contact-page">
    <section id="contact-map" className="contact_map_class" />
    <div id="contact-flex-wrapper" className="contact_flex_wrapper_class">
      <SectionBlockquote>
        <FormattedMessage
          id="contact.blockquote.content"
          defaultMessage="AnHui HuaYi Foods Co., Ltd has been a trusted supplier to McDonald's, Burger King, Subway, McCormick, KFC, Griffith Foods, Kerry Group, The Kraft Heinz Company, and others for over 20 years."
        />
      </SectionBlockquote>
      <section id="contact-content">
        <section id="contact-content_info" className="contact_content_class">
          <h5>
            <FormattedMessage
              id="contact.callout"
              defaultMessage="For enquiries about products, feedback, and customer support – Contact HuaYi. We’ll endeavour to respond as soon as possible."
            />
          </h5>
        </section>
        <section
          id="contact-content_details"
          className="contact_content_details"
        >
          <h6>
            <FormattedMessage
              id="contact.company1.name"
              defaultMessage="Anhui Hua Yi Foods Co., Ltd"
            />
          </h6>
          <p>
            <FormattedMessage
              id="contact.company1.address_line_1"
              defaultMessage="9 Zhenjiang Rd"
            />
            <br />
            <FormattedMessage
              id="contact.company1.address_line_2"
              defaultMessage="Nanqiao, Chuzhou"
            />
            <br />
            <FormattedMessage
              id="contact.company1.address_line_3"
              defaultMessage="Anhui, China 239000"
            />
          </p>
          <h6>
            <FormattedMessage
              id="contact.company2.name"
              defaultMessage="Yiyang food Co., Ltd"
            />
          </h6>
          <p>
            <FormattedMessage
              id="contact.company2.address_line_1"
              defaultMessage="7 Zhenjiang Rd"
            />
            <br />
            <FormattedMessage
              id="contact.company2.address_line_2"
              defaultMessage="Nanqiao, Chuzhou"
            />
            <br />
            <FormattedMessage
              id="contact.company2.address_line_3"
              defaultMessage="Anhui, China 239000"
            />
          </p>
          <p>
            <strong>
              <FormattedMessage
                id="contact.sales_department_title"
                defaultMessage="Sales Department:"
              />
            </strong>{' '}
            +86 550 3214008
            <br />
            <strong>
              <FormattedMessage
                id="contact.other_enquiries_title"
                defaultMessage="Other Enquiries:"
              />
            </strong>{' '}
            +86 550 3211766
            <br />
            <strong>
              <FormattedMessage id="contact.fax_title" defaultMessage="Fax:" />
            </strong>{' '}
            +86 550 3214238
            <br />
            <strong>
              <FormattedMessage
                id="contact.email_title"
                defaultMessage="Email:"
              />
            </strong>{' '}
            info@huayifoods.com
          </p>
        </section>
      </section>
    </div>
  </div>

export default Contact
