import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const itemImage1Src = require('imgs/product_pickle_1.jpg')
const itemImage2Src = require('imgs/product_pickle_2.jpg')
const itemImage3Src = require('imgs/product_pickle_3.jpg')
const itemImage4Src = require('imgs/product_pickle_4.jpg')
const itemImage1 = {
  background: 'url(' + itemImage1Src + ')no-repeat',
  backgroundSize: 'cover',
}
const itemImage2 = {
  background: 'url(' + itemImage2Src + ')no-repeat',
  backgroundSize: 'cover',
}
const itemImage3 = {
  background: 'url(' + itemImage3Src + ')no-repeat',
  backgroundSize: 'cover',
}
const itemImage4 = {
  background: 'url(' + itemImage4Src + ')no-repeat',
  backgroundSize: 'cover',
}

const SectionProductsItemBannerGherkin = () => {
  return (
    <section className="products_item_banner products_pickles">
      <div className="products_item_banner_main_description">
        <div className="products_item_banner_main_description_wrapper">
          <h3>
            <FormattedMessage
              id="products.item.gherkin.title"
              defaultMessage="Our Pickled Gherkins:"
            />
          </h3>
          <p>
            <FormattedMessage
              id="products.item.gherkin.description"
              defaultMessage="
              We provide custom flavoured gherkins with different shape and various packaging methods. Currently our dill sweet flavour and dill flavour are the most popular gherkins for our clients. We have different size of packaging options (from 1 KG to 2.5 KG per package). we also provide Jar packaging method to our Gherkins.
              You can also choose your own custom thickness of the sliced gherkins.
              "
            />
          </p>
          <p>
            <FormattedMessage
              id="products.item.gherkin.cta"
              defaultMessage="
              For all of the enquiries about our gherkins or any custom enquiries:
              "
            />
          </p>
          <p>
            <Link to="/contact" className="button-small button-small-green_bg">
              <FormattedMessage
                id="products.item.gherkin.contact.link"
                defaultMessage="Contact HuaYi &ndash;"
              />
            </Link>
          </p>
        </div>
      </div>
      <div className="products_item_banner_images">
        <div className="products_item_banner_image_row">
          <div className="products_item_banner_image" style={itemImage1} />
          <div className="products_item_banner_image" style={itemImage2} />
        </div>
        <div className="products_item_banner_image_row">
          <div className="products_item_banner_image" style={itemImage3} />
          <div className="products_item_banner_image" style={itemImage4} />
        </div>
      </div>
    </section>
  )
}

export default SectionProductsItemBannerGherkin
