import React from 'react'

const SectionTechImageBanner = () => {
  return (
    <section className="tech_image_banner">
      <ul>
        <li className="tech_wall_image_1">&nbsp;</li>
        <li className="tech_wall_image_2">&nbsp;</li>
        <li className="tech_wall_image_3">&nbsp;</li>
        <li className="tech_wall_image_4">&nbsp;</li>
      </ul>
    </section>
  )
}

export default SectionTechImageBanner
