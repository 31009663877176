import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const SectionProduct = () => {
  return (
    <section className="section-product">
      <div className="section-product_content-wrapper">
        <div className="section-product_content">
          <h1>
            <FormattedMessage
              id="home.section.product.content"
              defaultMessage="Delicious, Crisp, Superior Quality Pickles Grown Naturally with Passion and Love"
            />
          </h1>
          <Link to="/products" className="button button-primary">
            <FormattedMessage
              id="home.section.product.link"
              defaultMessage="View Products &ndash;"
            />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default SectionProduct
