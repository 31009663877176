import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionHistoryValue = () => {
  return (
    <section className="history_value">
      <div className="history_value_wrapper">
        <p className="history_value_description">
          <FormattedMessage
            id="history.value.description.line1"
            defaultMessage="
              We put high focus and detailed attention on our food safety standard.
            "
          />
        </p>
        <p className="history_value_description">
          <FormattedMessage
            id="history.value.description.line2"
            defaultMessage="
            From seeding to the final ready to eat product, we are deeply passionate in improving our manuafacturing technique.
            "
          />
        </p>
        <p className="history_value_description about_value_description_title">
          <FormattedMessage
            id="history.value.description.line3"
            defaultMessage="
            We believe that our delicious, crisp, and superior quality pickles are achieved with:
            "
          />
        </p>
        <ul className="history_value_description about_value_description_title">
          <li>
            <FormattedMessage
              id="history.value.description.line3.item1"
              defaultMessage="
            Professional technical team
            "
            />
          </li>
          <li>
            <FormattedMessage
              id="history.value.description.line3.item2"
              defaultMessage="
            Fully approved & applied certified food systems
            "
            />
          </li>
          <li>
            <FormattedMessage
              id="history.value.description.line3.item3"
              defaultMessage="
            Transparent raw material (cucumber) supply chain
            "
            />
          </li>
        </ul>
      </div>
    </section>
  )
}

export default SectionHistoryValue
