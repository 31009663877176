import React from 'react'
import SectionProduct from './Section_product'
import SectionTech from './Section_tech'
import SectionBlockquote from './Section_blockquote'
import SectionHistory from './Section_history'
import SectionYiyang from './Section_yiyang'

const Home = () =>
  <span>
    <SectionProduct />
    <SectionTech />
    <SectionBlockquote />
    <SectionHistory />
    <SectionYiyang />
  </span>

export default Home
