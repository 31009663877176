import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionHistoryTimeline = () => {
  return (
    <section className="history_timeline">
      <div className="history_timeline_line" />
      <ul>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.1994.jan"
              defaultMessage="
              1994, January
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.1994.jan.description"
              defaultMessage="
              Anhui Huayi foods Co. is established
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />

          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.1995.march"
              defaultMessage="
              1995, March
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.1995.march.description"
              defaultMessage="
              built the first cucumber farm base
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2003.jan"
              defaultMessage="
              2003, January
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2003.jan.description"
              defaultMessage="
              Yiyang(Chuzhou) food Co. is established as part of Sino-joint Japanese venture
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2003.dec"
              defaultMessage="
              2003, December
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2003.dec.description"
              defaultMessage="
              Became official supplier partner of MacDonald's
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2005.dec"
              defaultMessage="
              2005, December
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2005.dec.description"
              defaultMessage="
              Became official supplier partner of Burger King
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2008.aug"
              defaultMessage="
              2008, August
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2008.aug.description"
              defaultMessage="
              Supplier partner for Beijing Olympic Games 2008
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2010.may"
              defaultMessage="
              2010, May
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2010.may.description"
              defaultMessage="
              Supplier partner for Shanghai World expo (universal exposition) 2010
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2010.nov"
              defaultMessage="
              2010, November
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2010.nov.description"
              defaultMessage="
              Supplier partner for 16th Asian Games: Guangzhou 2010
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2011.jan"
              defaultMessage="
              2011, January
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2011.jan.description"
              defaultMessage="
              Became official supplier partner of Heinz
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2013.jan"
              defaultMessage="
              2013, January
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2013.jan.description"
              defaultMessage="
              Finished Sino-joint Japanese Venture
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2013.jul"
              defaultMessage="
              2013, July
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2013.jul.description"
              defaultMessage="
              Became official supplier partner of Subway
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2014.dec"
              defaultMessage="
              2014, December
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2014.dec.description"
              defaultMessage="
              Became official supplier partner of YUM!
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2016.jun"
              defaultMessage="
              2016, June
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2016.jun.description"
              defaultMessage="
              Macdonald’s outstanding Quality Control and management leadership qualified, A Class Supplier for year 2015 – MacDonald's Food quality Department
              "
            />
          </p>
        </li>
        <li className="history_timeline_event">
          <span className="history_timeline_linebridge" />
          <h5 className="history_timeline_time">
            <FormattedMessage
              id="history.timeline.2016.aug"
              defaultMessage="
              2016, August
              "
            />
          </h5>
          <p className="history_timeline_description">
            <FormattedMessage
              id="history.timeline.2016.aug.description"
              defaultMessage="
              Became official supplier partner of McCormick
              "
            />
          </p>
        </li>
      </ul>
    </section>
  )
}

export default SectionHistoryTimeline
