import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

const { func, string } = PropTypes
const logo_src = require('imgs/logo.svg')
const logo_cn_src = require('imgs/logo-cn.svg')

class NavHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showNavBg: false,
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    const hideNavBgCondition = scrollTop > 10 && !this.state.showNavBg
    const showNavBgCondition = scrollTop <= 10 && this.state.showNavBg

    if (hideNavBgCondition || showNavBgCondition) {
      this.setState(() => {
        return {
          showNavBg: !this.state.showNavBg,
        }
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const topClassName = this.state.showNavBg
      ? 'header_main header_main-scroll'
      : 'header_main'
    const topClassNameBg = this.state.showNavBg
      ? 'header_main-bg header_main-bg-scroll'
      : 'header_main-bg'

    return (
      <header className={topClassName}>
        <div className={topClassNameBg} />
        <div className="header_wrapper">
          <NavLink
            exact
            className="header_logo"
            to="/"
            alt="华怡食品 - AnHui HuaYi &ndash; Foods Company Limited"
          >
            <img
              className="svg home-icon"
              src={this.props.language === 'zh' ? logo_cn_src : logo_src}
              alt="华怡食品 - AnHui HuaYi &ndash; Foods Company Limited"
            />
          </NavLink>

          <nav>
            <ul className="nav_desktop">
              <li>
                <NavLink exact to="/" activeClassName="navActive">
                  <FormattedMessage
                    id="header.navigation.home"
                    defaultMessage="Home"
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/technology" activeClassName="navActive">
                  <FormattedMessage
                    id="header.navigation.technology"
                    defaultMessage="Technology"
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/history" activeClassName="navActive">
                  <FormattedMessage
                    id="header.navigation.history"
                    defaultMessage="History"
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/products" activeClassName="navActive">
                  <FormattedMessage
                    id="header.navigation.products"
                    defaultMessage="Products"
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="navActive">
                  <FormattedMessage
                    id="header.navigation.contact"
                    defaultMessage="Contact"
                  />
                </NavLink>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => this.props.updateLanguage('zh')}
                  className={this.props.language === 'zh' ? 'active' : ''}
                >
                  中文
                </a>{' '}
                |{' '}
                <a
                  href="javascript:void(0);"
                  onClick={() => this.props.updateLanguage('en')}
                  className={this.props.language === 'en' ? 'active' : ''}
                >
                  English
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

NavHeader.propTypes = {
  updateLanguage: func,
  language: string,
}

export default NavHeader
