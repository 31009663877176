import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { animateScroll as scroll } from 'react-scroll'

class Footer extends Component {
  scrollToTop() {
    scroll.scrollToTop()
  }

  render() {
    return (
      <footer className="footer_main">
        <div className="footer_wrapper">
          <p>
            <FormattedMessage
              id="footer.company_name"
              defaultMessage="&copy; AnHui HuaYi Foods Company Ltd"
            />
          </p>
          <a className="back-to-top" onClick={this.scrollToTop}>
            <FormattedMessage
              id="footer.back_to_top"
              defaultMessage="Back to top &#8593;"
            />
          </a>
        </div>
      </footer>
    )
  }
}

export default Footer
