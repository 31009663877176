import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const SectionTechDescription1Content = () => {
  return (
    <div className="techDesc1ContentWrapper">
      <h3>
        <FormattedMessage
          id="tech.description_1.content_header"
          defaultMessage="Anhui HuaYi Farm apply GAP (Good Agricultural Practice) management
        System during the whole planting season."
        />
      </h3>
      <hr />
      <p>
        <FormattedMessage
          id="tech.description_1.content_body"
          defaultMessage="
          GAP (Good Agricultural Practice) is a system for ensuring that crops are
          consistently produced and controlled according to quality standards. It
          is designed to minimize the risks involved in any pharmaceutical
          production that cannot be eliminated through testing the final product.
          "
        />
      </p>
      <Link to="/products" className="button-small button-small-green_bg">
        <FormattedMessage
          id="tech.description_1.link_to_products"
          defaultMessage="
          View our products &ndash;
          "
        />
      </Link>
    </div>
  )
}

export default SectionTechDescription1Content
