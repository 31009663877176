import React from 'react'
import PropTypes from 'prop-types'
import withSizes from 'react-sizes'
import SectionTechHeroBanner from './Section_tech_hero_banner'
import SectionTechImageBanner from './Section_tech_image_banner'
import SectionTechDescription1 from './Section_tech_description_1'
import SectionTechDescription2 from './Section_tech_description_2'
import SectionTechDescription3 from './Section_tech_description_3'
import SectionBlockquote from 'components/Section_blockquote'
import { FormattedMessage } from 'react-intl'

const { bool } = PropTypes

const Techs = ({ isMobile }) =>
  <div id="tech-page">
    <SectionTechHeroBanner />
    <SectionTechImageBanner />
    <SectionTechDescription1 isMobile={isMobile} />
    <SectionTechDescription2 />
    <SectionTechDescription3 />
    <SectionBlockquote>
      <FormattedMessage
        id="tech.blockquote.content"
        defaultMessage="
        AnHui HuaYi Team are rich with experiences, we are utilising automatic
        production line with high level of clean process environment, and zero
        preservative products.
        "
      />
    </SectionBlockquote>
  </div>

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
})

Techs.propTypes = {
  isMobile: bool,
}

export default withSizes(mapSizesToProps)(Techs)
