import React from 'react'
import SectionHistoryHeroBanner from './Section_history_hero_banner'
import SectionHistoryTimeline from './Section_history_timeline'
import SectionHistoryCertificates from './Section_history_certificates'
import SectionHistoryValue from './Section_history_value'
import SectionBlockquote from 'components/Section_blockquote'
import { FormattedMessage } from 'react-intl'

const History = () =>
  <div id="history-page">
    <SectionHistoryHeroBanner />
    <SectionHistoryTimeline />
    <SectionHistoryCertificates />
    <SectionHistoryValue />
    <SectionBlockquote>
      <FormattedMessage
        id="history.blockquote.content"
        defaultMessage="
        AnHui HuaYi Team are rich with experiences, we are utilising automatic
        production line with high level of clean process environment, and zero
        preservative products.
        "
      />
    </SectionBlockquote>
  </div>

export default History
