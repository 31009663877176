import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const SectionTech = () => {
  return (
    <section className="section-tech">
      <div className="section-tech_content-wrapper">
        <ul className="section-tech_content-list">
          <li className="section-tech_content-list_img-1" />
          <li className="section-tech_content-list_img-2" />
          <li className="section-tech_content-list_text">
            <h3>
              <FormattedMessage
                id="home.section.technology.title"
                defaultMessage="Our Technology"
              />
            </h3>
            <p>
              <FormattedMessage
                id="home.section.technology.content"
                defaultMessage="We only grow pickled gherkins using high quality non-GMO seeds, and produce ZERO nitrites during the fermentation process."
              />
            </p>
            <Link
              to="/technology"
              className="button-small button-small-green_bg"
            >
              <FormattedMessage
                id="home.section.technology.link"
                defaultMessage="Read more &ndash;"
              />
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default SectionTech
