import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Home from 'pages/Home/index'
import Tech from 'pages/Tech/index'
import History from 'pages/History/index'
import Products from 'pages/Products/index'
import Contact from 'pages/Contact/index'
import NavHeader from 'components/Navigation_header'
import Footer from 'components/Footer'
import NavMobile from 'components/Navigation_mobile'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import zh from 'react-intl/locale-data/zh'
import zhLocaleData from 'translation/locales/zh.json'
import enLocaleData from 'translation/locales/en.json'
import ScrollToTop from 'components/ScrollToTop'

const localeData = { zh: zhLocaleData, en: enLocaleData }
const defaultLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage
const defaultLangWithoutRegionCode = defaultLanguage
  .toLowerCase()
  .split(/[_-]+/)[0]
addLocaleData([...en, ...zh])

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: defaultLangWithoutRegionCode,
    }
  }

  updateLanguage(language) {
    return this.setState({ language })
  }

  render() {
    return (
      <IntlProvider
        locale={this.state.language}
        messages={localeData[this.state.language]}
      >
        <Router>
          <div id="outer-container" className={this.state.language}>
            <main id="page-wrap">
              <div>
                <NavHeader
                  updateLanguage={lang => this.updateLanguage(lang)}
                  language={this.state.language}
                />
                <ScrollToTop>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/technology" component={Tech} />
                    <Route exact path="/history" component={History} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/contact" component={Contact} />
                    <Route render={() => <p>Not Found</p>} />
                  </Switch>
                </ScrollToTop>
                <Footer />
                <NavMobile
                  updateLanguage={lang => this.updateLanguage(lang)}
                  language={this.state.language}
                />
              </div>
            </main>
          </div>
        </Router>
      </IntlProvider>
    )
  }
}
