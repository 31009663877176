import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link, NavLink } from 'react-router-dom'

const { func, string } = PropTypes
const menu_src = require('imgs/menu.svg')
const pickles_src = require('imgs/pickles.svg')
const contact_src = require('imgs/contact.svg')
const logo_mobile_nav_src = require('imgs/logo-mobile-nav.svg')
const logo_mobile_nav_cn_src = require('imgs/logo-cn.svg')

class NavMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clicked: false,
    }

    this.addClass = this.addClass.bind(this)
  }

  addClass() {
    let currentState = this.state.clicked

    this.setState(() => {
      return {
        clicked: !currentState,
      }
    })
  }

  render() {
    let classNameState = !this.state.clicked
      ? 'mobile_menu'
      : 'mobile_menu mobile_menu-active'

    return (
      <div>
        <nav className="mobile_nav">
          <ul>
            <li>
              <button
                id="burger_menu"
                className="mobile_nav-button"
                onClick={this.addClass}
              >
                <img src={menu_src} alt="menu" />
                <span className="mobile_nav-button_text">
                  <FormattedMessage
                    id="mobile.navigation.dock_menu_left"
                    defaultMessage="Menu"
                  />
                </span>
              </button>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                className="mobile_nav-button"
                onClick={() =>
                  this.props.language === 'en'
                    ? this.props.updateLanguage('zh')
                    : this.props.updateLanguage('en')}
              >
                <img src={contact_src} alt="menu" />
                <span className="mobile_nav-button_text">
                  {this.props.language === 'en' ? '中文' : 'English'}
                </span>
              </a>
            </li>
            <li>
              <Link to="/history" className="mobile_nav-button">
                <img src={pickles_src} alt="menu" />
                <span className="mobile_nav-button_text">
                  <FormattedMessage
                    id="mobile.navigation.dock_menu_right"
                    defaultMessage="History"
                  />
                </span>
              </Link>
            </li>
          </ul>
        </nav>

        <nav className={classNameState}>
          <ul>
            <li>
              <img
                className="mobile_nav_logo"
                src={this.props.language === 'zh' ? logo_mobile_nav_cn_src : logo_mobile_nav_src}
                alt="华怡食品 - Anhui HuaYi Foods Company Ltd"
              />
            </li>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="navActiveMobile"
                onClick={this.addClass}
              >
                <FormattedMessage
                  id="mobile.navigation.home"
                  defaultMessage="Home"
                />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/technology"
                activeClassName="navActiveMobile"
                onClick={this.addClass}
              >
                <FormattedMessage
                  id="mobile.navigation.technology"
                  defaultMessage="Technology"
                />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/history"
                activeClassName="navActiveMobile"
                onClick={this.addClass}
              >
                <FormattedMessage
                  id="mobile.navigation.history"
                  defaultMessage="History"
                />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/products"
                activeClassName="navActiveMobile"
                onClick={this.addClass}
              >
                <FormattedMessage
                  id="mobile.navigation.products"
                  defaultMessage="Products"
                />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="navActiveMobile"
                onClick={this.addClass}
              >
                <FormattedMessage
                  id="mobile.navigation.contact"
                  defaultMessage="Contact"
                />
              </NavLink>
            </li>
            <li className="mobile_menu-close">
              <button onClick={this.addClass}>
                <FormattedMessage
                  id="mobile.navigation.close_menu"
                  defaultMessage="Close menu"
                />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

NavMobile.propTypes = {
  updateLanguage: func,
  language: string,
}

export default NavMobile
