import React from 'react'
import { FormattedMessage } from 'react-intl'

const haccp_cert_src = require('imgs/cert-HACCP.png')
const gmp_cert_src = require('imgs/cert-GMP.png')
const iso22000_cert_src = require('imgs/cert-ISO22000.png')
const fssc22000_cert_src = require('imgs/cert-fssc22000.png')
const taccp_cert_src = require('imgs/cert-TACCP.png')

const SectionHistoryCertificates = () => {
  return (
    <section className="history_certificates">
      <h3 className="history_certificates_title">
        <FormattedMessage
          id="history.certificates.title"
          defaultMessage="Certificates"
        />
      </h3>
      <p className="history_certificates_description">
        <FormattedMessage
          id="history.certificates.description"
          defaultMessage="
          Anhui HuaYi is extremely committed to manufacturing our food products in line with strict international food safety standards.
          "
        />
      </p>
      <ul className="history_certificates_list">
        <li className="history_certificates_item">
          <img src={haccp_cert_src} alt="" />
        </li>
        <li className="history_certificates_item">
          <img src={gmp_cert_src} alt="" />
        </li>
        <li className="history_certificates_item">
          <img src={iso22000_cert_src} alt="" />
        </li>
        <li className="history_certificates_item">
          <img src={fssc22000_cert_src} alt="" />
        </li>
        <li className="history_certificates_item">
          <img src={taccp_cert_src} alt="" />
        </li>
      </ul>
    </section>
  )
}

export default SectionHistoryCertificates
