import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const SectionTechDescription3 = () => {
  return (
    <section className="tech_description_3">
      <div className="tech_lastSection">
        <div className="tech_lastSection_left">
          <h4>
            <FormattedMessage
              id="tech.description_3.content_header"
              defaultMessage="
              We using Lactobacillus bacteria during our fermentation.
              "
            />
          </h4>
          <p>
            <FormattedMessage
              id="tech.description_3.content_body"
              defaultMessage="
              Lactobacillus bacteria have the ability to convert sugars into
              lactic acid. The Lactobacillus strain is so named because it was
              first studied in milk ferments. These bacteria readily use lactose
              or other sugars and convert them quickly and easily to lactic acid.
              However, lacto-fermentation does not necessarily need to involve
              dairy products. Lactic acid is a natural preservative that inhibits
              the growth of harmful bacteria. Beyond preservation advantages,
              lacto-fermentation also increases or preserves the vitamin and
              enzyme levels, as well as digestibility, of the fermented food. In
              addition, lactobacillus organisms are heavily researched for
              substances that may contribute to good health. In the canning and
              freezing process some or all of the nutrients present in the freshly
              picked food are lost. Lacto-fermentation enhances the nutritive
              value of the food, and many enzymes and probiotics are created.
              Thus, our final fermentation products have more nutrients than the
              freshly picked cucumber. Other than this, our products also Low in
              Salinity, PH and non nitrite with high acidity.
              "
            />
          </p>
          <Link to="/products" className="button-small button-small-green_bg">
            <FormattedMessage
              id="tech.description_3.link_to_products"
              defaultMessage="
              View our products &ndash;
              "
            />
          </Link>
        </div>
        <div className="tech_lastSection_right">&nbsp;</div>
      </div>
    </section>
  )
}

export default SectionTechDescription3
