import React from 'react'
import { FormattedMessage } from 'react-intl'

const SectionTechHeroBanner = () => {
  return (
    <section className="tech_hero_banner">
      <div className="tech_hero_banner_bg_top" />
      <div className="tech_hero_banner_bg_bottom">
        <div className="tech_hero_banner_tile">
          <div className="tect_hero_banner_tile_left">&nbsp;</div>
          <div className="tech_hero_banner_tile_content">
            <h3>
              <FormattedMessage
                id="tech.hero_banner.content_header"
                defaultMessage="
                Anhui Huayi Pickle seeds only use NON GMO (Genetically modified
                food) seeds with High disease resistance AND High production
                factors.
                "
              />
            </h3>
            <hr />
            <p>
              <FormattedMessage
                id="tech.hero_banner.content_body"
                defaultMessage="
                Why NON GMO: A GMO is an organism whose genome has been altered by
                the techniques of genetic engineering so that its DNA contains one
                or more genes not normally found there. Note: A high percentage of
                food crops, such as corn and soybeans, are genetically modified.
                Scientists and consumer and environmental groups have cited many
                health and environmental risks with foods containing GMOs. Non-GMO
                means non-genetically modified organisms. We promise only NON-GMO
                cucumber SEEDS can be used for our products.
                "
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTechHeroBanner
