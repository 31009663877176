import React from 'react'
import { PropTypes } from 'prop-types'

const SectionBlockquote = props => {
  return (
    <section className="section-blockquote">
      <div className="section-blockquote_content-wrapper">
        <div className="section-blockquote_content">
          <div className="quote">
            <span className="quote-content">&ldquo;</span>
          </div>
          <blockquote>
            {props.children}
          </blockquote>
          <div className="quote">
            <span className="quote-content">&rdquo;</span>
          </div>
        </div>
      </div>
    </section>
  )
}

SectionBlockquote.propTypes = {
  children: PropTypes.node,
}

export default SectionBlockquote
